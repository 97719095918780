import ReactDOM from "react-dom";

import {
  getPaintingDataRequest,
  formatPaintingData,
} from "./paint-list.service";

import React, { useState, createContext, useEffect } from "react";

import {
  migratePaintsToFireStore,
  retrieveFirebasePaintings,
  migratePaintsWithImagesfromFireStore,
} from "../3-party/firebase.utils";

export const PaintingContext = createContext();

export const PaintingsContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paintings, setPaintings] = useState([]);

  const retrievePaintings = (option) => {
    setIsLoading(true);
    //setPaintings([]);
    console.log("retrieve painting start");

    if (option === true) {
      setTimeout(() => {
        getPaintingDataRequest()
          .then()
          .then((results) => {
            setIsLoading(false);
            console.log("retrive painting success ");
            setPaintings(results);
          })
          .catch((err) => {
            setIsLoading(false);
            setError(err);
            console.log(err);
          });
      }, 30000);
    } else {
      retrieveFirebasePaintings()
        .then()
        .then((results) => {
          setIsLoading(false);
          console.log("retrive painting success ");
          setPaintings(randomiseOrder(results));
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  };

  const collectionMap = () => {
    let flatList = [];
    paintings.forEach((item) => {
      flatList.push(item.collection);
    });

    flatList = flatList.flat(2);

    const categories = [];
    flatList.forEach((item) =>
      !categories.includes(item) ? categories.push(item) : categories
    );
    categories.unshift("Collections");
    return categories;
  };

  const yearMap = () => {
    let flatList = [];
    paintings.forEach((item) => {
      flatList.push(item.year);
    });

    flatList = flatList.flat(2);

    const categories = [];
    flatList.forEach((item) =>
      !categories.includes(item) ? categories.push(item) : categories
    );
    categories.unshift("Years");
    return categories;
  };

  const colorMap = () => {
    let flatList = [];
    paintings.forEach((item) => {
      flatList.push(item.dominantColor);
    });

    flatList = flatList.flat(2);

    const categories = [];
    flatList.forEach((item) =>
      !categories.includes(item) ? categories.push(item) : categories
    );
    categories.unshift("Colors");
    return categories;
  };

  const randomiseOrder = (paintingResults) => {
    const ramdomisedPaintings = paintingResults.reduce((acc, item) => {
      const ramdom = Math.round(Math.random() * 100);
      const newItem = { ...item, order: ramdom };
      return [...acc, newItem];
    }, []);
    return ramdomisedPaintings;
  };

  useEffect(async () => {
    try {
      await retrievePaintings(false);
      //await migratePaintsWithImagesfromFireStore("paintings", paintings);
    } catch (e) {
      console.log(e);
    }
    document.title = "CéO Painting Webshop";

  }, []);

  // useEffect(() => {
  //   randomiseOrder();
  // }, [paintings]);

  return (
    <PaintingContext.Provider
      value={{ paintings, isLoading, error, collectionMap, colorMap, yearMap }}
    >
      {children}
    </PaintingContext.Provider>
  );
};
