import React, { useContext, useState } from "react";

import {
  CartFullScreenContainer,
  Title,
  CaptionText,
  ShoppingShipIcon,
  ValidateOrderContainer,
  TitleOrdering,
} from "./cart-full-screen.style.component";
import CircularProgress from "@mui/material/CircularProgress";

import { CartLineDetail } from "./cart-line.component";

import { CartContext } from "../../services/cart/cart.context";
import { AuthenticationContext } from "../../services/authentication/authentication.context";

export const CartFullScreenComponent = () => {
  const {
    cart,
    addToCart,
    removeFromCart,
    total,
    validateOrder,
    isLoading,
  } = useContext(CartContext);

  const { user, hasOrdered } = useContext(AuthenticationContext);
  console.log("Cart creation: ");

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <CartFullScreenContainer>
      {cart.length < 1 ? (
        <Title>Your Cart is empty for the moment</Title>
      ) : (
        <Title>Your Cart</Title>
      )}
      {cart.map((item) => {
        return (
          <CartLineDetail
            key={item.id}
            painting={item}
            add={addToCart}
            remove={removeFromCart}
          />
        );
      })}
      {total > 0 && <Title>Your total order: {total}</Title>}
      {hasOrdered & (total > 0) ? (
        <Title>
          You have already placed an order in the past, are you sure you want to
          validate?
        </Title>
      ) : null}
      {total > 0 && (
        <ValidateOrderContainer
          onClick={() => {
            validateOrder(cart, user);
          }}
        >
          <ShoppingShipIcon />
          <TitleOrdering>Validate your order, get it in 2 weeks</TitleOrdering>
        </ValidateOrderContainer>
      )}
    </CartFullScreenContainer>
  );
};
