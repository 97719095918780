import React, { useContext, useEffect, useState } from "react";

import { PaintCardItem } from "./paint-item.component";
import { BoxModified } from "./paint-item.styles.component";
import Masonry from "@mui/lab/Masonry";

import CircularProgress from "@mui/material/CircularProgress";

import { PaintingContext } from "../../services/paint-list/paint-list.context";

export const PaintList = ({ filter }) => {
  const { isLoading, paintings } = useContext(PaintingContext);

  const [displayPainting, setDisplayPainting] = useState([...paintings]);

  const filterAction = () => {
    if (!paintings || !filter) {
      return [""];
    }

    let filteredPaintings = [...paintings];

    if (filter.collection !== "Collections") {
      filteredPaintings = paintings.filter((item) =>
        item.collection.includes(filter.collection)
      );
    }
    if (filter.year !== "Years") {
      const filteredPaintings2 = filteredPaintings.filter(
        (item) => item.year === filter.year
      );

      filteredPaintings = [...filteredPaintings2];
    }

    if (filter.color !== "Colors") {
      const filteredPaintings3 = filteredPaintings.filter((item) =>
        item.dominantColor.includes(filter.color)
      );
      filteredPaintings = [...filteredPaintings3];
    }

    return filteredPaintings;
  };

  useEffect(() => {
    setDisplayPainting([...filterAction()]);
  }, [filter, paintings]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (

      <Masonry columns={{xs: 2, sm: 2, lg: 3}} spacing={{xs: 0.3, sm: 1}}>
        {displayPainting
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <PaintCardItem key={index} paintData={item} />
          ))}
      </Masonry>

  );
};
