import emailjs, { init } from "@emailjs/browser";

init("LHTZcT2Dv6hN2dbCL");

const destructureUserOrder = (user) => {
  // prepare user data for validation email
  return {
    to_name: user.displayName,
    to_email: user.email,
  };
};

const prepareMessageOrder = (order) => {
  // prepare message and order details
  let orderMessage = "";
  for (const item of order) {
    const orderLine = item.name + " - quantity: " + item.qty + " ~[]~ ";
    orderMessage += orderLine;
  }

  return {
    order_number: "1242",
    order_message: orderMessage,
  };
};

export const sendOrderWithEmailJS = async (orderDetails, user) => {
  const orderMessageValidate = prepareMessageOrder(orderDetails);
  const userMessageValidate = destructureUserOrder(user);

  const messagePayload = {
    ...orderMessageValidate,
    ...userMessageValidate,
    reply_to: "marques.francois@outlook.com",
  };

  console.log(messagePayload);

  await emailjs
    .send("service_rp1ino9", "template_53914e2", messagePayload)
    .catch((e) => console.log(e));
};
