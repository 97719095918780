import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { PaintingContext } from "../../services/paint-list/paint-list.context";
import { CartContext } from "../../services/cart/cart.context";
import { retrieveImageURL } from "../../services/paint-list/paint-list.service";

import {
  FullScreenContainer,
  FullScreenImageContainer,
  LeftActionButtonContainer,
  BottomDescriptionContainer,
  FullScreenImage,
  RightActionButtonContainer,
  PaintTitle,
  CollectionTitle,
  YearTitle,
  Poetry,
  BackButton,
  ForwardButton,
  InCartContainer,
} from "./picture-full-screen.style.components";

export const PictureFullScreen = ({ painting = {} }) => {
  const {
    name = "Blue Sky",
    photoUri = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/La_Gioconda.jpg/1920px-La_Gioconda.jpg",
    collection = ["Grands espaces", "Savane"],
    id = "B01",
    price = "200",
    year = "2022",
    sold = false,
    promoted = false,
    dominantColor = "Blue",
    poetry = "un poeme, deux poemes",
    imageURLSrc = "",
  } = painting;

  const { addToCart, isInCart, howManyInCart } = useContext(CartContext);
  const { paintings } = useContext(PaintingContext);
  const params = useParams();
  const navigate = useNavigate();

  const FilteredPainting = paintings.filter((item) => {
    return params.paintId.toUpperCase() === item.id;
  });

  if (FilteredPainting.length) {
    return (
      <FullScreenContainer>
        <LeftActionButtonContainer>
          <BackButton onClick={() => navigate(-1)} />
        </LeftActionButtonContainer>
        <FullScreenImageContainer>
          <FullScreenImage src={FilteredPainting[0].url} />
        </FullScreenImageContainer>
        <RightActionButtonContainer>
          <ForwardButton
            onClick={() => {
              addToCart(FilteredPainting[0], 1);
            }}
          />
        </RightActionButtonContainer>
        <BottomDescriptionContainer>
          <PaintTitle>{FilteredPainting[0].name}</PaintTitle>
          <CollectionTitle>
            {FilteredPainting[0].collection.reduce((acc, item) => {
              return acc + " " + item;
            }, "")}
          </CollectionTitle>
          <YearTitle>{FilteredPainting[0].year}</YearTitle>
          {FilteredPainting[0].sold && (
            <Poetry>
              This original is sold already, you can order a print.
            </Poetry>
          )}
          <Poetry>{FilteredPainting[0].poetry}</Poetry>
          {isInCart(FilteredPainting[0].id) && (
            <InCartContainer>
              <ForwardButton />
              <Poetry>
                Already {howManyInCart(FilteredPainting[0].id)} in the cart
              </Poetry>
            </InCartContainer>
          )}
        </BottomDescriptionContainer>
      </FullScreenContainer>
    );
  }

  return (
    <FullScreenContainer>
      <LeftActionButtonContainer>
        <BackButton onClick={() => navigate(-1)} />
      </LeftActionButtonContainer>
      <FullScreenImageContainer>
        <FullScreenImage src={photoUri} />
        <BottomDescriptionContainer>
          <PaintTitle>{name}</PaintTitle>
          <CollectionTitle>{collection[0]}</CollectionTitle>
          <YearTitle>{year}</YearTitle>
          <Poetry>{poetry}</Poetry>
        </BottomDescriptionContainer>
      </FullScreenImageContainer>
      <RightActionButtonContainer>
        <ForwardButton onClick={() => null} />
      </RightActionButtonContainer>
    </FullScreenContainer>
  );
};
