import React from "react";
import { AboutContainer,AboutScreenImage, AboutScreenText, AboutScreenText2, AboutScreenImage2} from "../features/about/about.styles.component";
import { retrieveUtilitaireFirebaseImageURL} from "../services/3-party/firebase.utils"

export const AboutScreen =  () => {

const aboutText = `I grew up in France, surrounded by art because my parents always loved it.
They inspired me with stories about French and Spanish painters. When I was younger I started expressing myself in poetry and music. After a few years of traveling, I am now based in Amsterdam. Paintings are a means for me to express myself. I find truth in creation.
Spreading COLORS, I try to make sense and meaning of my surroundings. My work can be seen as full of energy and rhythm. I hope it will feed or at least disturb the senses of those who will watch it.`

const aboutText2 = "Selected work is available to buy. International shipping is available. You can select this option at checkout. Please don’t hesitate to contact me for any enquiries you might have - sizing or placing advice, best fit for your interior style, delivery options, or anything else."

  return (<AboutContainer>
<AboutScreenImage src={"https://firebasestorage.googleapis.com/v0/b/paco-painting-webshop.appspot.com/o/utilitaires%2FIMG-20210218-WA0012.jpg?alt=media&token=c6f4ee78-9a38-46c0-b760-65333c02eea6"}/>
<AboutScreenText>{aboutText}</AboutScreenText>
<AboutScreenImage2 src={"https://firebasestorage.googleapis.com/v0/b/paco-painting-webshop.appspot.com/o/utilitaires%2FIMG-20220129-WA0017.jpg?alt=media&token=9325290a-72c0-4b2b-8147-cad93cf3f080"}/>
<AboutScreenText2>{aboutText2}</AboutScreenText2>
  </AboutContainer>)


};
