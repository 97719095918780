import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

export const loginRequest = (email, password) => {
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const registerRequest = (email, password) => {
  const auth = getAuth();
  console.log("user creation started: user - " + email + " - pass " + password);
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const openAuthRequest = () => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      return user;
      // ...
    } else {
      return null;
    }
  });
};

export const logOutRequest = () => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
