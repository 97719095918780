import React, { useState, useEffect } from "react";

import {
  Container,
  FilterDisplayContainer,
} from "../features/homepage/filter/filter-selection.style.component";
import { PaintList } from "../features/homepage/paint-list.component";
import { PaintListContainer } from "../features/homepage/paint-item.styles.component";

export const PaintingCatalogue = () => {
  const [filter, setFilter] = useState();

  useEffect(() => console.log("from the parent", filter), [filter]);
  useEffect(() => {
    const tempFilter = {
      collection: "Collections",
      color: "Colors",
      year: "Years",
    };
    setFilter(tempFilter);
  }, []);

  return (
    <Container>
      <FilterDisplayContainer filter={filter} setFilter={setFilter} />
      <PaintListContainer>
        <PaintList filter={filter} />
      </PaintListContainer>
    </Container>
  );
};
