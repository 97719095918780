import React from "react";
import styled from "styled-components";

export const ContactContainer = styled.div`
display: grid;
grid-template-columns:
  minmax(10px, auto) minmax( auto, 520px) minmax(10px, auto);
  height: 100%;
  width: 100%;
  margin: auto;
  margin-bottom: ${(props) => props.theme.space[3]};
  padding-top: ${(props) => props.theme.sizes[2]};
  align-items: center;
`;

export const ContactScreenImage = styled.img`
grid-column: 2 / span 1;
  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-width: 500px) {

  }
`;
