import {
  CartLineContainer,
  Title,
  CaptionText,
  MinusButton,
  PlusButton,
  RemoveButton,
  ImageSmall,
  TextCartLineContainer,
} from "./cart-line.styles.component";

import {
  CartSmallScreenContainer,
  CaptionTextSmall,
  CartSmallTextContainer,
} from "./cart-small.styles.component";

export const CartLineDetail = ({ painting, add, remove }) => {
  return (
    <CartLineContainer>
      <ImageSmall src={painting.url} />

      <Title>{painting.name}</Title>

      <TextCartLineContainer>
        <CaptionText>Price:</CaptionText>
        <CaptionText>{painting.price}</CaptionText>
        <MinusButton onClick={() => remove(painting, 1)} />
        <CaptionText>Quantity:</CaptionText>
        <CaptionText>{painting.qty}</CaptionText>
        <PlusButton onClick={() => add(painting, 1)} />
        <RemoveButton onClick={() => remove(painting, 20)} />
      </TextCartLineContainer>
    </CartLineContainer>
  );
};

export const CartLineDetailSmall = ({ painting }) => {
  return (
    <CartSmallScreenContainer>
      <ImageSmall src={painting.photoUri} />
      <CartSmallTextContainer>
        <CaptionTextSmall>{painting.name}</CaptionTextSmall>
        <CaptionTextSmall>Quantity:</CaptionTextSmall>
        <CaptionTextSmall>{painting.qty}</CaptionTextSmall>
      </CartSmallTextContainer>
    </CartSmallScreenContainer>
  );
};
