import React from "react";
import styled from "styled-components";

import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineShoppingCart,
} from "react-icons/ai";

export const FullScreenContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(5px, auto) minmax(auto, 50px) minmax(auto, 300px) minmax(auto, 300px)
    50px minmax(5px, auto);
  margin-top: ${(props) => props.theme.sizes[3]};
  align-items: center;
`;

export const FullScreenImageContainer = styled.div`
  width: 100%;
  grid-column: 3 / span 2;
  grid-row: 1 / span 1;
  @media only screen and (max-width: 700px) {
    grid-column: 2 / span 4;
    grid-row: 1 / span 1;
  }
`;

export const BackButton = styled(AiOutlineDoubleLeft).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))`
  height: ${(props) => props.theme.sizes[4]};
`;

export const LeftActionButtonContainer = styled.div`
  width: 100%;
  z-index: 999;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  margin-right: ${(props) => props.theme.space[3]};
  margin-left: ${(props) => props.theme.space[1]};
  justify-items: center;
  @media only screen and (max-width: 700px) {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
  }
`;

export const RightActionButtonContainer = styled.div`
  grid-column: 5 / span 1;
  grid-row: 1 / span 1;
  z-index: 999;

  @media only screen and (max-width: 700px) {
    grid-column: 5 / span 1;
    grid-row: 2 / span 1;
  }
`;

export const ForwardButton = styled(AiOutlineShoppingCart).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))`
  height: ${(props) => props.theme.sizes[5]};
  margin-right: ${(props) => props.theme.space[3]};
  margin-left: ${(props) => props.theme.space[3]};
`;

export const BottomDescriptionContainer = styled.div`
  grid-column: 3 / span 2;
  grid-row: 2 / span 1;
`;

export const PaintTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  margin: ${(props) => props.theme.space[0]};
  text-align: left;
  padding-left: ${(props) => props.theme.space[3]};
`;

export const CollectionTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  margin: ${(props) => props.theme.space[0]};
  text-align: left;
  padding-left: ${(props) => props.theme.space[3]};
`;

export const YearTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  margin: ${(props) => props.theme.space[0]};
  text-align: left;
  padding-left: ${(props) => props.theme.space[3]};
  margin-bottom: ${(props) => props.theme.space[1]};
`;
export const Poetry = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  margin: ${(props) => props.theme.space[0]};
  text-align: left;
  padding-left: ${(props) => props.theme.space[3]};
  margin-bottom: ${(props) => props.theme.space[1]};
`;

export const InCartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  margin-bottom: ${(props) => props.theme.sizes[3]};
`;

export const FullScreenImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
