import React, { createContext, useState, useEffect, useContext } from "react";

import { getCartDataRequest, formatCartData } from "./cart.service";
import { createOrderDocumentFromAuth } from "../3-party/firebase.utils";
import { sendOrderWithEmailJS } from "../3-party/email-js.utils";
import { AuthenticationContext } from "../authentication/authentication.context";

export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const { isAuthenticated, user } = useContext(AuthenticationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  const buildMockCart = () => {
    setIsLoading(true);
    setCart([]);
    getCartDataRequest()
      .then((results) => {
        setIsLoading(false);
        setCart(results);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  };

  const saveCart = async (value, uid) => {
    try {
      const jsonValue = JSON.stringify(value);
      await window.localStorage.setItem(`@cart-${uid}`, jsonValue);
    } catch (error) {
      console.log("error storage: ", error);
    }
  };

  const getCart = async (uid) => {
    try {
      const jsonValue = await window.localStorage.getItem(`@cart-${uid}`);

      return jsonValue != null ? setCart(JSON.parse(jsonValue)) : null;
    } catch (error) {
      console.log("error reading: ", error);
    }
  };

  const addToCart = (painting, number) => {
    console.log("Add action " + painting.name);

    const filteredCart = cart.filter((item) => item.id !== painting.id);
    const selectedItem = cart.filter((item) => item.id === painting.id);

    if (selectedItem.length > 0) {
      console.log("Already existing");
      const newQty = selectedItem[0].qty + number;
      const newPainting = { ...painting, qty: newQty };
      const newCart = [...filteredCart, newPainting];
      setCart(newCart);
    } else {
      console.log("new item");
      const modifiedPainting = { ...painting, qty: number };
      const addedCart = [...cart, modifiedPainting];
      setCart(addedCart);
    }
  };

  const removeFromCart = (painting, number) => {
    console.log("remove action : " + painting.name);
    const filteredCart = cart.filter((item) => item.id !== painting.id);
    const selectedItem = cart.filter((item) => item.id === painting.id);

    if (selectedItem) {
      const newQty = selectedItem[0].qty - number;
      if (newQty > 0) {
        const newPainting = { ...painting, qty: newQty };
        const newCart = [...filteredCart, newPainting];
        setCart(newCart);
      } else {
        setCart(filteredCart);
      }
    }
  };

  const getTotal = () => {
    const Result = cart.reduce((acc, item) => {
      return item.qty * item.price + acc;
    }, 0);

    return setTotal(Result);
  };

  const isInCart = (uid) => {
    const filteredCart = cart.filter((item) => item.id === uid);

    if (filteredCart.length) {
      return true;
    }
    return false;
  };

  const howManyInCart = (uid) => {
    if (!isInCart) {
      return 0;
    }

    const filteredCart = cart.filter((item) => item.id === uid);

    return filteredCart[0].qty;
  };

  const validateOrder = async (cart, user) => {
    if (!isAuthenticated) {
      alert(
        "In order to place an order, please sign in or login. Go to the cart menu and register. Thanks!"
      );
      return null;
    }
    setIsLoading(true);

    await createOrderDocumentFromAuth(cart, user).then;
    sendOrderWithEmailJS(cart, user)
      .then(emptyCart)
      .then(
        alert(
          "Your order has been processed. Please check your mailbox, Paco will contact you shortly!"
        )
      )
      .catch((e) => console.log(e))
      .catch((e) => console.log(e));

    setIsLoading(false);
  };

  const emptyCart = () => {
    setCart([]);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCart(user.uid);
    } else {
      setCart([]);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      saveCart(cart, user.uid);
    }
  }, [cart, user]);

  useEffect(() => {
    getTotal();
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        error,
        isLoading,
        total,
        isInCart: isInCart,
        validateOrder: validateOrder,
        howManyInCart: howManyInCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
