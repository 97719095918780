import React from "react";
import styled from "styled-components";

export const AboutContainer = styled.div`
display: grid;
grid-template-columns:
  minmax(10px, auto) minmax(150px, 300px) 15px minmax(150px, 300px) minmax(10px, auto);
  grid-template-rows: auto 15px auto;
  height: 100%;
  width: 100%;
  margin: auto;
  margin-bottom: ${(props) => props.theme.space[3]};
  padding-top: ${(props) => props.theme.sizes[2]};
  align-items: center;
`;

export const AboutScreenImage = styled.img`
grid-column: 2 / span 1;

  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-width: 500px) {
    grid-column: 2 / span 3;
    grid-row: 1 / span 1;
  }
`;

export const AboutScreenText = styled.p`
grid-column: 4 / span 1;
text-align: justify;
  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-width: 500px) {
    grid-column: 2 / span 3;
    grid-row: 3 / span 1;
    margin: 0px;
  }
`;

export const AboutScreenImage2 = styled.img`
grid-column: 4 / span 1;
grid-row: 3 / span 1;
  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-width: 500px) {
    grid-column: 2 / span 3;
    grid-row: 4 / span 1;

  }
`;

export const AboutScreenText2 = styled.p`
grid-column: 2 / span 1;
grid-row: 3 / span 1;
text-align: justify;
  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-width: 500px) {
    grid-column: 2 / span 3;
    grid-row: 5 / span 1;
  }
`;
