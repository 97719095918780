export const paintData = [

   {
    name: "Skylines",
    photoUri: "02.jpg",
    collection: ["Abstract","Paysage"],
    id: "A02",
    price: "50",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
 {
    name: "les Bouteilles du Petit Collins",
    photoUri: "03.jpg",
    collection: ["Abstract","Bouteilles"],
    id: "A03",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Les Bouteilles d'Espagne 01/02",
    photoUri: "04.jpg",
    collection: ["Abstract","Bouteilles"],
    id: "A04-1",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Les Bouteilles d'Espagne 02/02",
    photoUri: "05.jpg",
    collection: ["Abstract","Bouteilles"],
    id: "A05-2",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Les Villes de Demain",
    photoUri: "06.jpg",
    collection: ["Abstract"],
    id: "A06",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","Pink"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Mon Tonton Vert 01/02",
    photoUri: "7.jpg",
    collection: ["Abstract"],
    id: "A08-1",
    price: "85",
    year: "2022",
    sold: true,
    promoted: false,
    dominantColor: ["Green"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Mon Tonton vert 02/02",
    photoUri: "8.jpg",
    collection: ["Abstract"],
    id: "A09-2",
    price: "85",
    year: "2022",
    sold: true,
    promoted: false,
    dominantColor: ["Green"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "soleil Jaune",
    photoUri: "soleiljaune.jpg",
    collection: ["Paysage","Abstract"],
    id: "A10",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Yellow"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Bleu Ocean",
    photoUri: "11.jpg",
    collection: ["Abstract"],
    id: "A11",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Haut en couleurs",
    photoUri: "abstractheadcouleurcouteau.jpg",
    collection: ["Abstract"],
    id: "A12",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Black","Yellow"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "FRANCE",
    photoUri: "abstractrougeetbleublanc.jpg",
    collection: ["Abstract"],
    id: "A13",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Corners",
    photoUri: "bleuabstractblanccouleurcouteau.jpg",
    collection: ["Abstract"],
    id: "A14",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["White","blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Boys",
    photoUri: "bleuabstractcouteau.jpg",
    collection: ["Abstract"],
    id: "A15",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","Pink"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },

  {
    name: "Recherche 2",
    photoUri: "bleuabstractcouteaumouvement.jpg",
    collection: ["Abstract"],
    id: "A16",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Bleu Nuit",
    photoUri: "bleuabstractnuitcouteau.jpg",
    collection: ["Abstract"],
    id: "A17",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Blue lights",
    photoUri: "bleulightabstractcouteau.jpg",
    collection: ["Abstract"],
    id: "A18",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Wavy",
    photoUri: "greenabstractoceancouteau.jpg",
    collection: ["Abstract"],
    id: "A19",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Recherche 3",
    photoUri: "rougeabstractcouteau.jpg",
    collection: ["Abstract"],
    id: "A20",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Red","Pink","White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Nautic",
    photoUri: "saveurnauticbleubvert.jpg",
    collection: ["Abstract"],
    id: "A22",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Recherche 4",
    photoUri: "vertabstractblanccouteau.jpg",
    collection: ["Abstract"],
    id: "A23",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Brown"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Italia",
    photoUri: "italiaabstrtactminimalist.jpg",
    collection: ["Abstract"],
    id: "A24",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Red","White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Case",
    photoUri: "Rougeabstractcubeorangelines.jpg",
    collection: ["Abstract"],
    id: "A25",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Red","Black"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Clarity",
    photoUri: "abstractrougereenrecherche.jpg",
    collection: ["Abstract"],
    id: "A26",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Clarity 2",
    photoUri: "bleuvertabstractclarte.jpg",
    collection: ["Abstract"],
    id: "A27",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Blue","Yellow"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "curacao",
    photoUri: "bouteillebleuvertcubeabstract.jpg",
    collection: ["Bouteilles"],
    id: "A28",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Purple table",
    photoUri: "bouteillespurpletablebleu.jpg",
    collection: ["Bouteilles"],
    id: "A29",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Cactus",
    photoUri: "chalkcactusrougeorange.jpg",
    collection: ["Dessins"],
    id: "A30",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Orange","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Balcony",
    photoUri: "chalkplantorange.jpg",
    collection: ["Dessins"],
    id: "A31",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Orange","Green"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Amsterdam 01/03",
    photoUri: "damabstract1:3.jpg",
    collection: ["Abstract"],
    id: "A32",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Bleu","Black","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Amsterdam 02/03",
    photoUri: "damabstract2:2.jpg",
    collection: ["Abstract"],
    id: "A33",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Bleu","Black","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Amsterdam 03/03",
    photoUri: "damabstract3:3.jpg",
    collection: ["Abstract"],
    id: "A34",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Bleu","Black","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Danse",
    photoUri: "danseabstraitred.jpg",
    collection: ["Abstract"],
    id: "A35",
    price: "150",
    year: "2022",
    sold: true,
    promoted: false,
    dominantColor: ["Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "NZ 01/02",
    photoUri: "greenredabstractboite1:2.jpg",
    collection: ["Abstract"],
    id: "A36",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "NZ 02/02",
    photoUri: "greenredabstractboite2:2.jpg",
    collection: ["Abstract"],
    id: "A37",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Lost kidz",
    photoUri: "lostkidzabstractrouge.jpg",
    collection: ["Abstract"],
    id: "A38",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Pink","Red","Black"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Circular",
    photoUri: "redabstractdessousdeverre.jpg",
    collection: ["Abstract"],
    id: "A39",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Dream",
    photoUri: "reveabstraitjaune.jpg",
    collection: ["Abstract"],
    id: "A40",
    price: "85",
    year: "2021",
    sold: false,
    promoted: false,
    dominantColor: ["Pink","Yellow"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Dark city",
    photoUri: "sombrepaysblackabstrctcity.jpg",
    collection: ["Abstract","Paysage"],
    id: "A41",
    price: "150",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Black","Green"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Street life",
    photoUri: "streetlifeabstractvendu.jpg",
    collection: ["Abstract","Dessins"],
    id: "A42",
    price: "85",
    year: "2022",
    sold: true,
    promoted: false,
    dominantColor: ["Black","Brown"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Groenblue",
    photoUri: "Capture d’écran 2022-04-24 à 19.36.38.jpg",
    collection: ["Abstract"],
    id: "A43",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","Green"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Green man",
    photoUri: "Capture d’écran 2022-04-24 à 19.37.45.jpg",
    collection: ["Abstract"],
    id: "A44",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Brown"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "YEE",
    photoUri: "Capture d’écran 2022-04-24 à 19.39.03.jpg",
    collection: ["Abstract"],
    id: "A45",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Yellow"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "cyclone",
    photoUri: "Capture d’écran 2022-04-24 à 19.41.53.jpg",
    collection: ["Abstract"],
    id: "A46",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Blue","Black"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "sombre",
    photoUri: "Capture d’écran 2022-04-24 à 19.47.42.jpg",
    collection: ["Abstract"],
    id: "A47",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Black","Red"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
  {
    name: "Architecture",
    photoUri: "Capture d’écran 2022-04-24 à 19.49.37.jpg",
    collection: ["Abstract"],
    id: "A48",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Brown"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
   {
    name: "Green Egg",
    photoUri: "green egg.jpg",
    collection: ["Abstract"],
    id: "A49",
    price: "85",
    year: "2022",
    sold: false,
    promoted: false,
    dominantColor: ["Green","Orange", "White"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
    {
    name: "Effort",
    photoUri: "Capture d’écran 2022-04-28 à 16.25.31.jpg",
    collection: ["Abstract"],
    id: "A50",
    price: "45",
    year: "2022",
    sold: true,
    promoted: false,
    dominantColor: ["Yellow","Black"],
    poetry: "A3- (29,37/42cm) (Schut) medium fine paper 180grm",
    srcImage: "",
  },
];
