import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaintItemContainer,
  PaintItemImage,
  PaintTitle,
  CollectionTitle,
  YearTitle,
  ShoppingIcon,
  PaintTextContainer,
  PaintBottomContainer,
} from "./paint-item.styles.component";

import { CartContext } from "../../services/cart/cart.context";

export const PaintCardItem = ({ paintData = {} }) => {
  const navigate = useNavigate();
  const { isInCart } = useContext(CartContext);

  let {
    name = "Blue Sky",
    photoUri = "https://www.thesprucecrafts.com/thmb/4O8SOyC7QaKT_X-YnPQLgMHp4BI=/941x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/GettyImages-922707682-5b90467bc9e77c0025931eef.jpg",
    collection = ["Grands espaces", "Savane"],
    id = "B01",
    price = "200",
    year = "2022",
    sold = false,
    dominantColor = "Blue",
    poetry = "un poeme, deux poemes",
    url = "",
  } = paintData;

  return (
    <PaintItemContainer elevation={5} onClick={() => navigate(id)}>
      <PaintItemImage loading="lazy" src={url} />
      <PaintBottomContainer>
        <PaintTextContainer>
          <PaintTitle>{name}</PaintTitle>
          <CollectionTitle>
            {collection.reduce((acc, item) => {
              return acc + " " + item;
            }, "")}
          </CollectionTitle>
          <YearTitle>{year}</YearTitle>
          {sold && <YearTitle>Print available</YearTitle>}
        </PaintTextContainer>
        {isInCart(id) && <ShoppingIcon />}
      </PaintBottomContainer>
    </PaintItemContainer>
  );
};
