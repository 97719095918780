import React, { createContext, useState, useEffect } from "react";
import {
  loginRequest,
  registerRequest,
  logOutRequest,
} from "./authentication.service";

import {
  getAuth,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";

import {
  signInWithGooglePopup,
  createUserDocumentFromAuth,
} from "../3-party/firebase.utils";

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [hasOrdered, setHasOrdered] = useState(false);

  const onLoginGoogle = () => {
    console.log("click google login");
    setIsLoading(true);

    signInWithGooglePopup()
      .then((userCredential) => {
        console.log("Google Login success : ");
        createUserDocumentFromAuth(userCredential.user).then((response) => {
          setHasOrdered(response.hasOrdered);
          setUser(userCredential.user);
          setIsLoading(false);
        });
      })
      .catch((e) => {
        setError(error.toString());
        setIsLoading(false);
      });
  };

  const onLogin = (email, password) => {
    setIsLoading(true);
    console.log("Loading user login...");

    loginRequest(email, password)
      .then(async (userCredential) => {
        // Signed in
        console.log("User Authentication started");
        await createUserDocumentFromAuth(userCredential.user).then(
          (response) => {
            setUser(response);
            setHasOrdered(response.hasOrdered);
            setIsLoading(false);
          }
        );

        console.log("User Authentication successfull");
        // ...
      })
      .catch((error) => {
        console.log("User Authentication Failled started");
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(error.toString());
        setIsLoading(false);
        console.log("User Authentication error", error);
      });
  };

  const onRegister = (email, password, repeatedPassword) => {
    setIsLoading(true);
    console.log("Loading user creation...");
    if (repeatedPassword !== password) {
      setError("Error: The two password entered do not match");
      return;
    }
    registerRequest(email, password)
      .then(async (userCredential) => {
        console.log("User Creation started");
        await createUserDocumentFromAuth(userCredential.user).then(
          (response) => {
            setUser(response);
            setHasOrdered(response.hasOrdered);
          }
        );
        setIsLoading(false);
        console.log("User Creation successfull");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(error.toString());
        setIsLoading(false);
        console.log("User creation error", error);
      });
  };

  const onLogOut = () => {
    console.log("user Sign out started");
    console.log(user.email);

    logOutRequest()
      .then(() => {
        setUser(null);
        setHasOrdered(false);
        setIsLoading(false);

        console.log("success signout");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(error.toString());
        setIsLoading(false);
        console.log("User logout error", error);
      });
  };

  const auth = getAuth();
  onAuthStateChanged(auth, async (usr) => {
    if (usr) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      await createUserDocumentFromAuth(usr).then((response) => {
        setHasOrdered(response.hasOrdered);
        setIsLoading(false);
      });
      setUser(usr);
      // ...
    } else {
      // User is signed out
      setIsLoading(false);
    }
  });

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: !!user,
        hasOrdered,
        user,
        isLoading,
        error,
        onLogin,
        onRegister,
        onLogOut,
        onLoginGoogle,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
