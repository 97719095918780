import { useContext } from "react";

import { AuthenticationScreen } from "../features/authentication/authentication.form.component";

import { CartFullScreenComponent } from "../features/ordering/cart-full-screen.component";
import { CartSmallScreenComponent } from "../features/ordering/cart-small.component";

import { AuthenticationContext } from "../services/authentication/authentication.context";

export const CartScreen = () => {
  const { isAuthenticated } = useContext(AuthenticationContext);
  return (
    <>
      <CartFullScreenComponent />
      <AuthenticationScreen />
    </>
  );
};
