import { useContext } from "react";

import { PaintingContext } from "../../../services/paint-list/paint-list.context";

import { Container, ContainerLine } from "./filter-selection.style.component";

import { SplitButton } from "./filter-selection-line.component";

export const FilterDisplay = ({ setFilter, filter }) => {
  const { collectionMap, colorMap, yearMap } = useContext(PaintingContext);

  const collectionList = collectionMap();
  const colorList = colorMap();
  const yearList = yearMap();

  const createFilter = (nameFilter) => {
    let newFilter = { ...filter };
    if (collectionList.includes(nameFilter)) {
      newFilter.collection = nameFilter;
    }
    if (colorList.includes(nameFilter)) {
      newFilter.color = nameFilter;
    }
    if (yearList.includes(nameFilter)) {
      newFilter.year = nameFilter;
    }

    setFilter(newFilter);
  };

  return (
    <ContainerLine>
      <SplitButton options={collectionList} createFilter={createFilter} />
      <SplitButton options={colorList} createFilter={createFilter} />
      <SplitButton options={yearList} createFilter={createFilter} />
    </ContainerLine>
  );
};
