import React from "react";
import styled from "styled-components";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { AiOutlineShoppingCart } from "react-icons/ai";

import { Text } from "../../infrastructure/reusable-components/text.component";

export const PaintListContainer = styled.div`
  grid-column: 3 / span 1;
  grid-row: 4 / span 1;
  display: inline;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: auto;
  padding-top: ${(props) => props.theme.sizes[2]};
`;

export const PaintItemContainer = styled(Paper)`
  direction: column;
  align-content: flex-start;
  max-width: 400px;
  margin: ${(props) => props.theme.sizes[1]};
  border-radius: 0px;
  @media only screen and (max-width: 400px) {
    max-width: 400px;
  }
`;

export const PaintItemImage = styled.img`
  max-width: 100%;
  &:hover {
    filter: grayscale(100%) blur(2px);
  }
`;

export const PaintBottomContainer = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  align-items: center;
  justify-content: space-between;
`;

export const PaintTextContainer = styled.div``;

export const PaintTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  margin: 0px;
  text-align: left;
  padding-left: ${(props) => props.theme.sizes[1]};
  @media only screen and (max-width: 400px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const CollectionTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  margin: 0px;
  text-align: left;
  padding-left: ${(props) => props.theme.sizes[1]};
  @media only screen and (max-width: 400px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const YearTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  margin: 0px;
  text-align: left;
  padding-left: ${(props) => props.theme.sizes[1]};
  margin-bottom: ${(props) => props.theme.sizes[1]};
  @media only screen and (max-width: 400px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const ShoppingIcon = styled(AiOutlineShoppingCart).attrs((props) => ({
  size: props.theme.sizesJS[2],
}))`
  margin-right: ${(props) => props.theme.sizes[1]};
`;
