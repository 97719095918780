import styled from "styled-components";

import {
  AiOutlineLogin,
  AiOutlineLogout,
  AiFillGoogleCircle,
} from "react-icons/ai";

export const AuthenticationContainer = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.theme.sizes[2]};
  margin-bottom: ${(props) => props.theme.sizes[2]};
`;
export const TextInputContainer = styled.div`
  flex-flow: row no-wrap;
  display: inline-flex;
  justify-content: space-between;
`;
export const LoginText = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const TitleLogin = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.title};
  }
`;

export const ErrorLogin = styled(TitleLogin)`
  color: ${(props) => props.theme.colors.ui.error};
  font-size: ${(props) => props.theme.fontSizes.caption};
`;

export const TextInputLogin = styled.input`
  font-family: ${(props) => props.theme.fonts.heading};
  width: 100%;
  margin-left: ${(props) => props.theme.sizes[1]};
  margin-top: ${(props) => props.theme.sizes[1]};
  margin-bottom: ${(props) => props.theme.sizes[1]};
`;
export const LoginButton = styled(AiOutlineLogin).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))``;

export const LogOutButton = styled(AiOutlineLogout).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))`
  margin-left: ${(props) => props.theme.sizes[1]};
`;

export const LoginGoogleButton = styled(AiFillGoogleCircle).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))``;

//Register part

export const AuthenticationRegisterContainer = styled.div`
  margin-left: ${(props) => props.theme.sizes[2]};
  margin-right: ${(props) => props.theme.sizes[2]};
  display: inline-flex;
  flex-flow: column wrap;
`;

// Login Container

export const AuthenticationLoginContainer = styled.div`
  margin-left: ${(props) => props.theme.sizes[2]};
  margin-right: ${(props) => props.theme.sizes[2]};
  display: inline-flex;
  flex-flow: column wrap;
`;

//export const AuthenticationContainer = styled.div``;
