import React, { useContext, useState } from "react";

import {
  AuthenticationContainer,
  AuthenticationRegisterContainer,
  AuthenticationLoginContainer,
  TitleLogin,
  LoginText,
  TextInputLogin,
  LoginButton,
  TextInputContainer,
  ErrorLogin,
  LogOutButton,
  LoginGoogleButton,
} from "./authentication.style.component";

import CircularProgress from "@mui/material/CircularProgress";

import { AuthenticationContext } from "../../services/authentication/authentication.context";

export const AuthenticationScreen = () => {
  const {
    isAuthenticated,
    onLogin,
    onRegister,
    user,
    isLoading,
    onLogOut,
    error,
    onLoginGoogle,
  } = useContext(AuthenticationContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [emailLogin, setEmailLogin] = useState("");

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isAuthenticated) {
    return (
      <AuthenticationContainer>
        <LoginText>Signed in as {user.email} - Want to logout? </LoginText>
        <LogOutButton
          onClick={() => {
            onLogOut();
          }}
        />
      </AuthenticationContainer>
    );
  }

  return (
    <AuthenticationContainer>
      <AuthenticationLoginContainer>
        <TitleLogin>Log in your account</TitleLogin>
        <TextInputContainer>
          <LoginText>Email:</LoginText>
          <TextInputLogin
            type="email"
            value={emailLogin}
            onChange={(e) => setEmailLogin(e.target.value)}
          />
        </TextInputContainer>
        <TextInputContainer>
          <LoginText>Password:</LoginText>
          <TextInputLogin
            type="password"
            value={passwordLogin}
            onChange={(e) => {
              setPasswordLogin(e.target.value);
            }}
          />
        </TextInputContainer>
        <LoginButton onClick={() => onLogin(emailLogin, passwordLogin)} />
        <TextInputContainer>
          <LoginText>Login with Google:</LoginText>
          <LoginGoogleButton onClick={() => onLoginGoogle()} />
        </TextInputContainer>
      </AuthenticationLoginContainer>

      <AuthenticationRegisterContainer>
        <TitleLogin>Create your account</TitleLogin>
        <TextInputContainer>
          <LoginText>Email: </LoginText>

          <TextInputLogin
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </TextInputContainer>
        <TextInputContainer>
          <LoginText>Password: </LoginText>

          <TextInputLogin
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </TextInputContainer>
        <TextInputContainer>
          <LoginText>Repeat the password: </LoginText>

          <TextInputLogin
            type="password"
            value={repeatedPassword}
            onChange={(e) => {
              setRepeatedPassword(e.target.value);
            }}
          />
        </TextInputContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <LoginButton
            onClick={() => {
              onRegister(email, password, repeatedPassword);
              console.log("login");
            }}
          />
        )}
        {error && <ErrorLogin>{error}</ErrorLogin>}
      </AuthenticationRegisterContainer>
    </AuthenticationContainer>
  );
};
