import React from "react";

import { useNavigate } from "react-router-dom";

import {
  HeaderContainer,
  HeaderTitle,
  HeaderLogoContainer,
  UnderlineLogoTitle,
  TabNavigationContainer,
  ButtonHeader,
  ButtonContainer,
  CartButton,
  HeaderLogoImage,
} from "./header.styles.component";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderLogoContainer>
        <HeaderLogoImage src="https://firebasestorage.googleapis.com/v0/b/paco-painting-webshop.appspot.com/o/utilitaires%2Fsignature.png?alt=media&token=bfb68d2a-1c5d-44dd-a439-ce64af726ce0" />
      </HeaderLogoContainer>

      <TabNavigationContainer>
        <ButtonContainer
          variant="outlined"
          aria-label="outlined primary button group"
        >
          <ButtonHeader onClick={() => navigate("/about")}>
            About me
          </ButtonHeader>
          <ButtonHeader onClick={() => navigate("/catalogue")}>
            Painting catalogue
          </ButtonHeader>
          <ButtonHeader onClick={() => navigate("/contactme")}>
            Contact me
          </ButtonHeader>
          <CartButton onClick={() => navigate("/cart")}>My Cart</CartButton>
        </ButtonContainer>
      </TabNavigationContainer>
    </HeaderContainer>
  );
};
