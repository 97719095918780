import styled from "styled-components";

import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillCloseCircle,
} from "react-icons/ai";

export const CartLineContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(5px, auto) minmax(auto, 100px) minmax(5px, 15px) minmax(200px, auto)
    minmax(5px, auto);
  grid-template-row: minmax(auto, 100px) minmax(auto, 100px);
  margin: auto;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #000;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  margin-right: ${(props) => props.theme.sizes[2]};
  grid-column: 4 / span 1;
  grid-row: 0 / span 1;

  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.body};
    margin-right: ${(props) => props.theme.sizes[1]};
  }
`;

export const TextCartLineContainer = styled.div`
  grid-column: 4 / span 1;
  grid-row: 2 / span 1;
  display: inline-flex row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 700px) {
  }
`;

export const CaptionText = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  margin-right: ${(props) => props.theme.sizes[1]};
  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
    margin-right: ${(props) => props.theme.sizes[0]};
  }
`;

export const MinusButton = styled(AiFillMinusCircle).attrs((props) => ({
  size: props.theme.sizesJS[2],
}))`
  margin-right: ${(props) => props.theme.sizes[2]};
  margin-left: ${(props) => props.theme.sizes[2]};
  @media only screen and (max-width: 700px) {
    margin-right: ${(props) => props.theme.sizes[1]};
    margin-left: ${(props) => props.theme.sizes[1]};
  }
`;

export const PlusButton = styled(AiFillPlusCircle).attrs((props) => ({
  size: props.theme.sizesJS[2],
}))``;

export const RemoveButton = styled(AiFillCloseCircle).attrs((props) => ({
  size: props.theme.sizesJS[2],
}))`
  margin-right: ${(props) => props.theme.sizes[2]};
  margin-left: ${(props) => props.theme.sizes[1]};
  @media only screen and (max-width: 700px) {
    margin-right: ${(props) => props.theme.sizes[1]};
    margin-left: ${(props) => props.theme.sizes[1]};
  }
`;

export const ImageSmall = styled.img`
  max-width: 100%;
  max-height: 100%;
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  margin-bottom: 5px;
  margin-top: 5px;
`;
