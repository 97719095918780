import styled from "styled-components";

import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillCloseCircle,
} from "react-icons/ai";

import Paper from "@mui/material/Paper";

export const CartSmallScreenContainer = styled(Paper).attrs((props) => ({
  elevation: 5,
}))`
  display: inline-flex;
  flex-flow: column wrap;
  width: 250px;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.theme.sizes[1]};
`;

export const CartSmallTextContainer = styled.div`
display: inline-flex
flex-flow: row wrap;
`;

export const CaptionTextSmall = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  margin-right: ${(props) => props.theme.sizes[1]};
`;

export const MinusButton = styled(AiFillMinusCircle).attrs((props) => ({
  size: props.theme.sizesJS[1],
}))`
  margin-right: ${(props) => props.theme.sizes[2]};
  margin-left: ${(props) => props.theme.sizes[2]};
`;

export const PlusButton = styled(AiFillPlusCircle).attrs((props) => ({
  size: props.theme.sizesJS[1],
}))``;

export const RemoveButton = styled(AiFillCloseCircle).attrs((props) => ({
  size: props.theme.sizesJS[1],
}))`
  margin-left: ${(props) => props.theme.sizes[2]};
`;
