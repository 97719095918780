import styled from "styled-components";

import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
display: grid;
grid-template-columns: minmax(20px,auto) minmax(auto,150px) minmax(auto,650px) minmax(10px, auto);
  align-items: center;
  width: 100%;
  max-height: ${(props) => props.theme.sizes[5]};
  margin-bottom: ${(props) => props.theme.space[1]};
  margin-top: ${(props) => props.theme.space[3]};
  @media only screen and (max-width: 700px) {
    grid-template-columns: minmax(20px,auto) minmax(auto,90px) minmax(auto,650px) minmax(10px, auto);
  }
`;
export const HeaderLogoContainer = styled.div`
grid-column: 2 / span 1;
`;

export const HeaderLogoImage = styled.img`

max-width: 150px;
@media only screen and (max-width: 700px) {
  max-width: 90px;
}
`;

export const HeaderTitle = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  margin: 0px;
  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.body};
    margin-bottom: ${(props) => props.theme.space[1]};
  }
`;
export const UnderlineLogoTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  @media only screen and (max-width: 700px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const TabNavigationContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  max-width: 800px;
`;
export const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ButtonHeader = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h5};
  padding: ${(props) => props.theme.space[2]};
  font-family: ${(props) => props.theme.fonts.heading};
  background-color: transparent;
  border-style: none;
  text-align: bottom;
  &:hover {
    background-color: ${(props) => props.theme.colors.brand.muted};
  }
  @media only screen and (max-width: 740px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
    padding: ${(props) => props.theme.space[1]};
  }
`;

export const CartButton = styled(ButtonHeader)`
  margin-left: ${(props) => props.theme.space[4]};
  @media only screen and (max-width: 740px) {
    margin-left: ${(props) => props.theme.space[1]};
  }
  @media only screen and (max-width: 384px) {
    margin-top: ${(props) => props.theme.space[3]};
  }
`;
