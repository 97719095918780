import styled from "styled-components";
import { FilterDisplay } from "./filter-display.component";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 5px minmax(auto, 800px) 5px auto;
  grid-template-row: auto auto;
  width: 100%;
`;

export const ContainerLine = styled.div`
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  display: flex row;
  justify-items: center;
  margin: auto;
`;

export const FilterDisplayContainer = styled(FilterDisplay)`
  grid-column: 3 / span 1;
  grid-row: 5 / span 1;
`;
