import { initializeApp } from "firebase/app";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  getFirestore,
  writeBatch,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBGPG0209wkSzMvrsJ_1AZj6I9sMkTjGXU",
  authDomain: "paco-painting-webshop.firebaseapp.com",
  projectId: "paco-painting-webshop",
  storageBucket: "gs://paco-painting-webshop.appspot.com/",
  messagingSenderId: "152337572084",
  appId: "1:152337572084:web:13de02a0e85dd7e7cc3ea1",
  measurementId: "G-FH95BY4QRQ",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export const db = getFirestore();

//Google auth set-up
const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});

const auth = getAuth();

export const signInWithGooglePopup = () => {
  console.log("prompt pop up");
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

// db User

export const createUserDocumentFromAuth = async (userAuth) => {
  console.log("create user to database");
  const userDocRef = doc(db, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  if (!userSnapshot.exists()) {
    //if not exists
    // create new user data and store it
    let { displayName, email } = userAuth;

    if (!displayName) {
      displayName = email;
    }
    const uid = userAuth.uid;
    const hasOrdered = false;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        uid,
        displayName,
        email,
        createdAt,
        hasOrdered,
      });
    } catch (e) {
      console.log("error creating the user in database: ", e.message);
    }
  }
  return userSnapshot.data();
};

// create order

export const createOrderDocumentFromAuth = async (cart, user) => {
  console.log("create Order to database");
  const userDocRef = doc(db, "orders", user.uid);

  const orderSnapshot = await getDoc(userDocRef);
  if (!orderSnapshot.exists()) {
    //if not exists
    // create new user data and store it
    const uid = user.uid;
    const email = user.email;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, { cart, email, uid, createdAt }).then(
        changeHasOrdered(user)
      );
    } catch (e) {
      console.log("error creating the order in database: ", e.message);
    }
  }

  return userDocRef;
};

const changeHasOrdered = async (user) => {
  const userDocRef = doc(db, "users", user.uid);
  console.log("change order started");
  await updateDoc(userDocRef, { hasOrdered: true })
    .then(() => {
      console.log("change order done");
    })
    .catch((e) => console.log(e));
};

//Collection to FireStore

export const migratePaintsToFireStore = async (collectionKey, objectsToAdd) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);
  objectsToAdd.forEach((item) => {
    const docRef = doc(collectionRef, item.id.toLowerCase());
    batch.set(docRef, item);
  });

  await batch.commit();
  console.log("done migratePaintsToFireStore");
};

//new version of migration with images previous version is deprecated.

export const migratePaintsWithImagesfromFireStore = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);
  console.log("start migratePaintsWithImagesfromFireStore");

  for await (const item of objectsToAdd) {
    console.log("start loop migration");
    const docRef = doc(collectionRef, item.id.toLowerCase());

    const location = "images/" + item.photoUri;
    const imagesRef = ref(storage, location);

    try {
      const imageURLSrc = await getDownloadURL(imagesRef);
      const newItem = { ...item, url: imageURLSrc };
      batch.set(docRef, newItem);
    } catch (e) {
      console.log(e);
    }
  }

  await batch.commit();
  console.log("done migratePaintsWithImagesfromFireStore");
};

export const retrieveFirebasePaintings = async () => {
  console.log("collection ref started");
  const collectionRef = collection(db, "paintings");

  return new Promise((resolve, reject) =>
    getDocs(collectionRef)
      .then((response) => {
        const paintDataFromFirebase = response.docs.reduce((acc, item) => {
          // doc.data() is never undefined for query doc snapshots

          return [...acc, item.data()];
        }, []);

        resolve(paintDataFromFirebase);
      })
      .catch(reject)
  );
};

export const retrieveOneFirebasePainting = async (referencePainting) => {
  console.log("collection 1 ref started");
  console.log(referencePainting);
  const docRef = collection(db, "paintings");

  const query = query(docRef, where("id", "==", referencePainting));

  const result = await getDocs(query);
  console.log(result);
  return result;
};

export const retrieveFirebaseImageURL = async (name) => {
  const location = "images/" + name;
  const imagesRef = ref(storage, location);

  try {
    const imageURLSrc = await getDownloadURL(imagesRef).catch((e) =>
      console.log(e)
    );
    console.log(imageURLSrc);
  } catch (e) {
    console.log(e);
  }
};

export const retrieveUtilitaireFirebaseImageURL = async (name) => {
  const location = "utilitaires/" + name;
  const imagesRef = ref(storage, location);


    const imageURLSrc = await getDownloadURL(imagesRef).catch((e) =>
      console.log(e)
    );
    console.log(imageURLSrc);
return imageURLSrc;

};
