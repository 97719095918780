import styled from "styled-components";

import { AiOutlineShop } from "react-icons/ai";

export const CartFullScreenContainer = styled.div`
  display: inline;
  flex-flow: Column wrap;
  width: 100%;
  justify-content: center;
  margin-top: ${(props) => props.theme.sizes[2]};
  margin-bottom: ${(props) => props.theme.sizes[0]};
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};
  text-align: center;
  @media only screen and (max-width: 740px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const TitleOrdering = styled.h1`
  font-family: ${(props) => props.theme.fonts.heading};

  @media only screen and (max-width: 740px) {
    font-size: ${(props) => props.theme.fontSizes.caption};
  }
`;

export const CaptionText = styled.p`
  font-family: ${(props) => props.theme.fonts.heading};
  &:hover {
    background-color: ${(props) => props.theme.colors.brand.muted};
  }
`;

export const ValidateOrderContainer = styled.button`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: ${(props) => props.theme.sizes[2]};
  border-radius: ${(props) => props.theme.sizes[0]};
  border: solid ${(props) => props.theme.sizes[0]}
    ${(props) => props.theme.colors.ui.secondary};
  background-color: transparent;

  &:hover {
    background-color: ${(props) => props.theme.colors.brand.muted};
  }
`;

export const ShoppingShipIcon = styled(AiOutlineShop).attrs((props) => ({
  size: props.theme.sizesJS[3],
}))``;
