import logo from "./logo.svg";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

import { ThemeProvider } from "styled-components";
import { theme } from "./infrastructure/theme/index";

import { PaintingCatalogue } from "./screens/paiting-catalogue.screen";
import { Header } from "./features/header/header.component";
import { AboutScreen } from "./screens/about.screen";
import { PictureFullScreen } from "./features/picture-full-screen/picture-full-screen.components";
import { CartScreen } from "./screens/cart.screen";
import { ContactMeScreen } from "./screens/contact.screen";

import { CartContextProvider } from "./services/cart/cart.context";
import { AuthenticationContextProvider } from "./services/authentication/authentication.context";
import { PaintingsContextProvider } from "./services/paint-list/paint-list.context";

const firebaseConfig = {
  apiKey: "AIzaSyBGPG0209wkSzMvrsJ_1AZj6I9sMkTjGXU",
  authDomain: "paco-painting-webshop.firebaseapp.com",
  projectId: "paco-painting-webshop",
  storageBucket: "gs://paco-painting-webshop.appspot.com/",
  messagingSenderId: "152337572084",
  appId: "1:152337572084:web:13de02a0e85dd7e7cc3ea1",
  measurementId: "G-FH95BY4QRQ",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthenticationContextProvider>
          <Header />
          <PaintingsContextProvider>
            <CartContextProvider>
              <Routes>
                <Route exact path="/" element={<PaintingCatalogue />} />
                <Route
                  exact
                  path="/catalogue"
                  element={<PaintingCatalogue />}
                />
                <Route path="/about" element={<AboutScreen />} />
                <Route path="/:paintId" element={<PictureFullScreen />} />
                <Route
                  path="/catalogue/:paintId"
                  element={<PictureFullScreen />}
                />
                <Route path="/cart" element={<CartScreen />} />
                <Route path="/contactme" element={<ContactMeScreen />} />
              </Routes>
            </CartContextProvider>
          </PaintingsContextProvider>
        </AuthenticationContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
