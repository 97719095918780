import { paintData } from "./paint-data.mock";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const getPaintingDataRequest = () => {
  return new Promise((resolve, reject) => {
    const result = paintData;
    if (!result) {
      reject("Painting list data not found");
    }
    console.log("service Request : ");
    console.log(result);
    resolve(result);
  });
};

export const retrieveImageURL = async (name) => {
  const storage = getStorage();
  const location = "images/" + name;
  const imagesRef = ref(storage, location);

  const imageURLSrc = await getDownloadURL(imagesRef).catch((e) =>
    console.log(e)
  );
  console.log(imageURLSrc);
  return imageURLSrc;
};

export const formatPaintingData = (entry) => {
  console.log("service retriving formatting : ");
  console.log(entry);

  const formattedResults = entry.map((painting) => {
    try {
      const imageURLSrc = retrieveImageURL(painting.photoUri);
      console.log(imageURLSrc);
      return { ...painting, srcImage: imageURLSrc };
    } catch (error) {
      console.log(error);
    }
  });
  console.log("formatted Results : ");
  console.log(formattedResults);
  return formattedResults;
};
